<template>
    <div>
      <div class="card">
        <div class="card-header">
          <strong style="color: #1985ac;">{{ $t('wdm16.4206') }} => </strong> {{ param_data.param_group }}
          <strong style="color: #1985ac;">{{ $t('wdm16.11229') }} => </strong> {{ param_data.param }}, 
          <strong v-if="param_data.sub_param" style="color: #1985ac;">{{ $t('wdm16.11291') }} => </strong> {{ param_data.sub_param }}
        </div>
        <div class="card-block">
          <div class="form-group col-12">

             <template v-if="new_copy_parameter">

              <div class="card">
                <div class="card-header">
                  <strong style="color: #1985ac;">{{ $t('wdm16.1129') }}</strong>
                </div>
                <div class="card-block">
                  <table class="table-striped">
                    <thead>
                      <tr>
                        <th>{{ $t('wdm16.1129') }}</th>
                        <th>{{ $t('wdm16.1129') }}</th>
                        <th>tr</th>
                        <th>en</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="text" class="form-control" v-model="param_data.new_label"></td>
                        <td><input type="text" class="form-control" v-model="new_copy_parameter.label"></td>
                        <td><input type="text" class="form-control" v-model="new_copy_parameter.translation.tr"></td>
                        <td><input type="text" class="form-control" v-model="new_copy_parameter.translation.en"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card" v-if="select_types.indexOf(new_copy_parameter.type) !== -1">
                <div class="card-header">
                  <strong style="color: #1985ac;">Yeni seçenek</strong>
                </div>
                <div class="card-block">
                  <table class="table-striped">
                    <thead>
                      <tr style="color: #1985ac;">
                        <th>{{ $t('wdm16.1129') }}</th>
                        <th>{{ $t('wdm16.1129') }}</th>
                        <th>tr</th>
                        <th>en</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="text" class="form-control" v-model="new_option.value"></td>
                        <td><input type="text" class="form-control" v-model="new_option.label"></td>
                        <td><input type="text" class="form-control" v-model="new_option.translation.tr"></td>
                        <td><input type="text" class="form-control" v-model="new_option.translation.en"></td>
                      </tr>
                      <tr>
                        <td colspan="4">
                            <div class="pull-right">
                              <button type="button" class="btn btn-primary" @click="add_option()">yeni seçeneği ekle</button>
                            </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card" v-if="select_types.indexOf(new_copy_parameter.type) !== -1">
                <div class="card-header">
                  <strong style="color: #1985ac;">Seçenekler</strong>
                </div>
                <div class="card-block">
                  <table class="table-striped">
                    <thead>
                      <tr style="color: #1985ac;">
                        <th></th>
                        <th>label</th>
                        <th>{{ $t('wdm16.11292') }}</th>
                        <th>tr</th>
                        <th>en</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(op, op_ind) in new_copy_parameter.options">
                        <td>
                          <div class="btn-group" role="group" style="padding-top: 8px;">
                            <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <!-- işlemler -->
                            </button>
                              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" @click="delete_option(op_ind)">
                                  sil
                                </button>
                                <button type="button" class="dropdown-item fa fa-arrow-up" @click="list_param_order(new_copy_parameter.options, op_ind, 'up')">
                                  yukarı al
                                </button>
                                <button type="button" class="dropdown-item fa fa-arrow-up" @click="list_param_order(new_copy_parameter.options, op_ind, 'down')">
                                  aşağı al
                                </button>
                              </div>                    
                          </div>
                        </td>
                        <td><input type="text" class="form-control" v-model="op.value"></td>
                        <td><input type="text" class="form-control" v-model="op.label"></td>
                        <td><input type="text" class="form-control" v-model="op.translation.tr"></td>
                        <td><input type="text" class="form-control" v-model="op.translation.en"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

             </template> 

          </div>
        </div>
      </div>

    </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
// import OptionService from '@/services/option';
// import moment from 'moment';

export default {
  name: 'OptionEdit',
  props: {
    new_copy_parameter: {
      type: Object,
      required: false
    },
    param_data: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      select_types: ['select', 'checkbox', 'object_list'],
      user: {},
      new_option: {caption: '', label: '', translation: {tr: '', en: ''}}
    };
  },
  methods: {
    add_option: function () {
      let det = false;
      for (let i in this.new_copy_parameter.options) {
        let x = this.new_copy_parameter.options[i];
        if (x.value === this.new_option.value || x.label === this.new_option.label || x.translation.tr === this.new_option.translation.tr || x.translation.en === this.new_option.translation.en) {
          det = true;
          break;
        }
      }
      if (!det) {
        if (!this.new_copy_parameter.options) {
          this.new_copy_parameter.options = [];
        }
        this.new_copy_parameter.options.push(JSON.parse(JSON.stringify(this.new_option)));
      } else {
        alert('Bu seçenek kayıtlıdır.');
      }
      this.$forceUpdate();
    },
    delete_option: function (option_ind) {
      this.new_copy_parameter.options.splice(option_ind, 1);
    }
  },
  watch: {},
  components: {
    vSelect
  }
};

</script>

<style>
</style>

import Vue from 'vue';
import { API_BASE_URL } from '@/config';

export default {
  save (data) {
    var uuid = 'new';
    if (data.id) {
      uuid = data.id;
    }
    let url = API_BASE_URL + 'protocol/save_protocol/' + uuid;
    return Vue.http.put(url, data);
  },
  save_my_protocol (data) {
    var uuid = 'new';
    if (data.id) {
      uuid = data.id;
    }
    let url = API_BASE_URL + 'protocol/save_my_protocol/' + uuid;
    return Vue.http.put(url, data);
  },
  save_all (data) {
    return Vue.http.put(API_BASE_URL + 'protocol/save_all/', data);
  },
  save_new_protocols (data) {
    return Vue.http.put(API_BASE_URL + 'protocol/save_new_protocols/', data);
  },
  search (data) {
    return Vue.http.post(API_BASE_URL + 'protocol/search', data);
  },
  get_one_cb_document (data) {
    return Vue.http.post(API_BASE_URL + 'protocol/get_one_cb_document', data);
  },
  get (uuid) {
    return Vue.http.get(API_BASE_URL + 'protocol/get_protocol/' + uuid);
  },
  get_my_protocol (uuid) {
    return Vue.http.get(API_BASE_URL + 'protocol/get_my_protocol/' + uuid);
  },
  get_my_protocol_list () {
    return Vue.http.get(API_BASE_URL + 'protocol/get_my_protocol_list/');
  },
  get_user_protocol_list (data) {
    return Vue.http.post(API_BASE_URL + 'protocol/get_user_protocol_list', data);
  },
  batch_edit_protocols (data) {
    return Vue.http.post(API_BASE_URL + 'protocol/batch_edit_protocols', data);
  },
  delete (uuid) {
    return Vue.http.delete(API_BASE_URL + 'protocol/delete_protocol/' + uuid);
  },
  make_my_favorite_protocol (uuid) {
    return Vue.http.put(API_BASE_URL + 'protocol/make_my_favorite_protocol/' + uuid);
  },
  delete_favorite (uuid) {
    return Vue.http.delete(API_BASE_URL + 'protocol/delete_favorite/' + uuid);
  },
  get_favorite_protocols (user_list) {
    return Vue.http.put(API_BASE_URL + 'protocol/favorite_list/', user_list);
  }
};

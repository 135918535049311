<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card style="margin-bottom: 0px;" no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Hasta Arama
          </b-card-header>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-form-group label="" label-for="search_patient">
                  <b-input-group>
                    <b-form-input id="search_patient" placeholder="Hasta Ad Soyad, WisdomEra hasta no, Medin Hasta No, Tc/Passport no ile arama yapabilirsiniz, tüm hastaları listelemek için 1 adet % işareti ile arama yapabilirsiniz" type="text" v-model="d_searchText" @keydown.enter.native="ClickEnter"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="success" @click="f_searchPatient()"><i class="fa fa-search"></i> ara</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-pagination class="pull-right" aria-controls="myProtocolsTable" v-model="d_patientListTableData['current']" :total-rows="d_patientList.length" :per-page="d_patientListTableData['per-page']"></b-pagination>
                <b-table id="searchPatientTable" :per-page="d_patientListTableData['per-page']" :current-page="d_patientListTableData['current']" thead-tr-class="" head-variant="dark" striped responsive stacked="sm" ref="ref_searchPatient" bordered small hover :items="d_patientList" :filter="d_searchText" :filter-included-fields="['firstname', 'lastname','record_id', 'personel_number', 'passport_number', 'himsPatientID']" :fields="searchPatientColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-hovered="" @row-unhovered="">
                  <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success">
                      <b-dropdown-item v-if="p_wdmrType === 'wdm17'" @click="f_addThisPatientToWdm17(row.item)">Hastayı seç</b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template v-slot:cell(rowNumber)="row">
                    {{ ((d_patientListTableData['current'] - 1) * d_patientListTableData['per-page']) + row.index + 1 }}
                  </template>
                  <template v-slot:cell(name)="row">
                    {{ w_presentation ? 'Hasta Adı Soyadı' : row.item.firstname.toLocaleLowerCase('tr') + ' ' + row.item.lastname.toLocaleLowerCase('tr') }}
                  </template>
                  <template v-slot:cell(sex)="row">
                    <i v-if="row.item.sex === '0'" class="fa fa-mars"></i>
                    <i v-else class="fa fa-venus"></i> {{ row.item.sex === '0' ? 'Erkek' : 'Kadın' }}
                  </template>
                  <template v-slot:cell(citizen_type)="row">
                    {{ w_presentation ? 'Vatandaşlık' : row.item.citizen_type === 'citizen' ? 'Türkiye' : 'Yabancı' }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PatientService from '@/services/patient';
import GlobalService from "@/services/global";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "PatientSearch",
  computed: mapGetters({
    lang: "lang",
    help: 'help'
  }),
  props: {
    param_change: {
      type: Object,
      required: false,
      default: () => {}
    },
    p_patientListDoc: {
      type: Object,
      required: true,
      default: () => {}
    },
    p_wdmrData: {
      type: Object,
      required: false,
      default: () => {}
    },
    p_wdmrType: {
      type: String,
      required: false
    },
    p_listAllPatients: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      w_presentation: false,
      user: {},
      d_searchText: '',
      searchPatientColumns: [{
        key: "actions",
        label: ""
      }, {
        key: "rowNumber",
        label: " "
      }, {
        key: "record_id",
        label: "WisdomEra Hasta No",
        sortable: true,
        formatter: value => {
          return this.w_presentation ? 'Dosya No' : value;
        }
      }, {
        key: "name",
        label: "Hasta Adı",
        sortable: true
      }, {
        key: "age",
        label: "yaş",
        sortable: true
      }, {
        key: "sex",
        label: "Cinsiyet",
        sortable: true
      }, {
        key: "personel_number",
        label: "Tc No",
        sortable: true,
        formatter: value => {
          return this.w_presentation ? 'Kimlik No' : value;
        }
      }, {
        key: "passport_number",
        label: "Pasaport No",
        formatter: value => {
          return this.w_presentation ? 'Pasaport No' : value;
        }
      }, {
        key: "himsPatientID",
        label: "Medin Hasta No",
        formatter: value => {
          return this.w_presentation ? 'HBYS No' : value;
        }
      }, {
        key: "citizen_type",
        label: "vatandaşlık",
        sortable: true
      }],
      d_patientList: [],
      d_patientListTableData: { 'current': 1, 'per-page': 50 },
      d_selectedHospitalGroup: '',
      d_selectedHospitalId: '',
      d_selectedProjectDate: ''
    };
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getHospitalGroupAndId();
    if (this.p_patientListDoc.document_bucket === 'default') {
      this.searchPatientColumns.push({
        key: "preassigned_username",
        label: "Ön tanımlı kullanıcı",
        sortable: true
      });
    }
    if (this.p_listAllPatients) {
      this.f_listAllPatients();
    }
  },
  mounted () {},
  methods: {
    f_addThisPatientToWdm17 (patient_data) {
      if (this.p_wdmrType === 'wdm17') {
        this.p_wdmrData.data.general.selected_patient_name = { 'val': patient_data.firstname + ' ' + patient_data.lastname };
        this.p_wdmrData.data.general.selected_patient_id = { 'val': patient_data.id };
        this.p_wdmrData.data.general.selected_patient_hospital_group = { 'val': this.d_selectedHospitalGroup };
        this.p_wdmrData.data.general.selected_patient_hospital_id = { 'val': this.d_selectedHospitalId };
        this.p_wdmrData.data.general.selected_patient_projectdate = { 'val': this.d_selectedProjectDate };
        this.p_wdmrData.data.general.selected_patient_hims_patient_id = { 'val': patient_data.himsPatientID };
        this.param_change_func('id');
      }
    },
    param_change_func: function (op) {
      if (this.param_change) {
        if (this.param_change[op]) {
          this.param_change[op] = 0;
        } else {
          this.param_change[op] = 1;
        }
      }
    },
    ClickEnter (event) {
      if (event.which === 13) {
        this.f_searchPatient();
      }
    },
    f_getHospitalGroupAndId: function () {
      GlobalService.get_one_cb_document({ 'key': this.p_patientListDoc.document_key, 'bucket': this.p_patientListDoc.document_bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            let patient_list_data = resp.data.result;
            this.d_selectedHospitalGroup = patient_list_data.hospital_group;
            this.d_selectedHospitalId = patient_list_data.hospital_id;
            if (patient_list_data.project_date) {
              this.d_selectedProjectDate = patient_list_data.project_date;
            }
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_searchPatient: function () {
      if (this.d_searchText && this.d_searchText !== '') {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta listesi getiriliyor.' } });
        PatientService.search_from_patientlist({ 'search': this.d_searchText, 'document_key': this.p_patientListDoc.document_key, 'document_bucket': this.p_patientListDoc.document_bucket })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              this.d_patientList = resp.data.result;
            } else {
              this.d_patientList = [];
            }
            if (this.d_searchText === '%') {
              this.d_searchText = '';
            }
          });
      } else {
        alert('Lutfen arama kriteri giriniz.');
      }
    },
    f_listAllPatients: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta listesi getiriliyor.' } });
      PatientService.search_from_patientlist({ 'search': '%', 'document_key': this.p_patientListDoc.document_key, 'document_bucket': this.p_patientListDoc.document_bucket })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            this.d_patientList = resp.data.result;
          } else {
            this.d_patientList = [];
          }
        });
    }
  },
  watch: {},
  components: {}
};

</script>

